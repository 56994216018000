import React, { Fragment } from "react"
import { graphql, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Global, css } from "@emotion/react"
import styled from "@emotion/styled"
import { cx } from "@emotion/css"
import mq from "@utils/mq"

import Img from "gatsby-image"
import Layout from "@components/layout"
import Button from "@components/button"

const style = css`
  ${mq("large")} {
    #gatsby-focus-wrapper {
      display: grid;
      height: 100vh;
      align-items: center;
    }
  }
`

const Wrap = styled.div`
  padding: 1rem;

  ${mq("large")} {
    display: grid;
    justify-content: center;
    padding: 2rem;
  }
`

const Content = styled.div`
  padding: 1.5rem;

  ${mq("small")} {
    padding: 2rem;
  }

  ${mq("medium")} {
    padding: 2.5rem;
  }
`

const Product = styled.section`
  display: grid;
  max-width: 32rem;
  align-items: start;
  margin-left: auto;
  margin-right: auto;

  h2 {
    font-family: var(--monospace);
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0.25rem;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  data {
    font-size: 1.25rem;
    font-family: var(--monospace);
    line-height: 1;
  }

  ${mq("small")} {
    h2 {
      font-size: 1.125rem;
    }

    h1 {
      font-size: 2rem;
    }

    data {
      font-size: 1.5rem;
    }
  }

  ${mq("large")} {
    max-width: none;
    grid-column-gap: 2rem;
    grid-template-columns: minmax(auto, 50rem) 32rem;
    align-items: center;
  }
`

const Image = styled.div`
  display: grid;

  figure {
    margin: 0;
  }

  ${mq("large")} {
    align-self: stretch;
    align-items: center;

    figure {
      grid-row-start: 1;
      grid-column-start: 1;
    }
  }
`

const Info = styled.article`
  border: 1px solid var(--border-color);
  position: relative;
`

const Sizes = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
`

const Size = styled.span`
  font-family: var(--monospace);
  color: var(--text-high);
  border: 1px solid var(--border-color);
  padding: 1rem 0.5rem;

  &.unavailable {
    color: var(--text-low);
    text-decoration: line-through;
  }
`

const Unavailable = styled.div`
  font-family: var(--monospace);
  font-size: 0.75rem;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
  background-color: var(--border-color);
  padding: 0.5rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);

  ${mq("large")} {
    transform: none;
  }
`

const Description = styled.div`
  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

function goBack(location) {
  if (location.state && location.state.referrer) {
    navigate(-1)
  }
  else {
    navigate("/")
  }
}

function toCurrency(cost) {
  return parseFloat(cost).toLocaleString(
    "en-US",
    { style: "currency", currency: "USD" }
  )
}

export default function ProductPageTemplate({ data: { allFile, mdx, mainPlaceholder }, location }) {
  const { nodes: images } = allFile
  const foundImage = images.find(image => image.relativePath.includes("/main"))
  const image = foundImage ? foundImage.childImageSharp.fluid : mainPlaceholder.childImageSharp.fluid

  const unavailable = mdx.frontmatter.status === "unavailable"

  return (
    <Layout>
      <Helmet>
        <title>{mdx.frontmatter.brand} {mdx.frontmatter.model} - DUNK.SUPPLY</title>
      </Helmet>
      <Global styles={style} />
      <Wrap>
        <Product>
          <Image>
            <BackButton onClick={() => goBack(location)} />
            <figure>
              <Img
                fluid={image}
                durationFadeIn={333}
                alt=""
              />
            </figure>
          </Image>
          <Info className={cx({
            "unavailable": mdx.frontmatter.status === "unavailable",
          })}>
            <Details
              body={mdx.body}
              brand={mdx.frontmatter.brand}
              collab={mdx.frontmatter.collab}
              model={mdx.frontmatter.model}
              price={mdx.frontmatter.price}
              size={mdx.frontmatter.size}
              unavailable={unavailable}
            />
            <hr style={{ margin: 0 }} />
            <Content>
              Style:
              {" "}
              <span style={{ fontFamily: "var(--monospace)" }}>
                {mdx.frontmatter.pid}
              </span>
            </Content>
          </Info>
        </Product>
      </Wrap>
    </Layout>
  )
}

function BackButton({ onClick, styles, ...props }) {
  return (
    <Button
      {...props}
      styles={`
        span {
          transform: scale(1.5);
          display: inline-flex;
          transition: transform ease 0.125s;
        }

        ${mq("large")} {
          font-size: 1rem;
          grid-row-start: 1;
          grid-column-start: 1;
          align-self: start;
        }
      `}
      onClick={onClick}
    >
      <span aria-hidden="true">‹</span>
      {" "}
      Back
    </Button>
  )
}

function Details({
  body,
  brand,
  collab,
  model,
  price,
  size,
  unavailable,
}) {
  return (
    <Fragment>
      {unavailable && (
        <Unavailable>Out of stock</Unavailable>
      )}
      <Content>
        <div style={{ marginBottom: "2rem" }}>
          <h2>{collab ? `${collab} x ` : null}{brand}</h2>
          <h1>{model}</h1>
          <data>{toCurrency(price)}</data>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <Sizes>
            <span>Size</span>
            <Size className={cx({
              "unavailable": unavailable,
            })}>&nbsp;{size}&nbsp;</Size>
          </Sizes>
        </div>
        <Description>
          <MDXProvider>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </Description>
      </Content>
    </Fragment>
  )
}

export const pageQuery = graphql`
  query($relativeSlug: String!) {
    mdx(
      fields: { relativeSlug: { eq: $relativeSlug } }
      fileAbsolutePath: { regex: "\\/products/" }
    ) {
      id
      body
      frontmatter {
        brand
        collab
        model
        pid
        price
        size
        status
      }
      fields {
        relativeSlug
        slug
      }
    }
    allFile(
      filter: {
        extension: { eq: "png" }
        relativeDirectory: { in: [$relativeSlug] }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
        relativeDirectory
        relativePath
      }
    }
    mainPlaceholder: file(
      sourceInstanceName: { eq: "images" },
      name: { eq: "main-placeholder" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
